import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectID } from 'bson';
import * as api from '../api-client';
import { Language, newSection, newTitle, Section, Status } from '../models';
import { Location } from '../models/location/Location';
import { AppState } from '../store';
import { updateSectionOrder } from './locationSlice';
import { addTopic } from './topicSlice';

export interface SectionMap {
	[id: string]: Section;
}
export interface SectionState {
	sections: SectionMap;
	selectedSectionId: string;
	isLoading: boolean;
}

const initSectionState: SectionState = {
	sections: {} as SectionMap,
	selectedSectionId: null,
	isLoading: false,
};

export const addSection = createAsyncThunk<Section, string, { state: AppState }>(
	'section/addSection',
	async (sectionName: string, { getState, dispatch }) => {
		const { selectedLocationId, locations } = getState().location;
		const selectedLocation = locations[selectedLocationId];
		let section = createNewSection(selectedLocation, sectionName);
		let newSectionOrder = [...selectedLocation.sectionOrder, section.id];
		//update the location
		dispatch(updateSectionOrder(newSectionOrder));
		//add the new section first. Otherwise addTopic will callback to update the topicOrder that doesn't exist yet.
		await dispatch(addNewSection(section));
		//make a new page for the new section. this will handle updating the section.topicOrder via a dispatch fired from "addTopic" Thunk
		dispatch(addTopic({ section, index: 0 }));
		return section;
	}
);

// NETWORK SAVES
export const saveSections = createAsyncThunk<void, void, { state: AppState }>(
	'section/saveSections',
	async (_, { getState, dispatch }) => {
		let { sections } = getState().section;
		Object.values(sections).forEach((section) => {
			api.saveSection(section);
			//don't need anything back from sections saves, but if it was a createNewEntity, we need to remove that flag so it doesn't create a new section the next time it gets saved
			if (section.createNewEntity) {
				dispatch(dropCreateNewSectionFlag({ id: section.id }));
			}
		});
		return;
	}
);

// SECTION SLICE
// Remember, it's only ok to modify state within these reducers because there's behind-the-scenes stuff going on that allows it.
// Normally, anywhere else, we don't modify state directly
const sectionSlice = createSlice({
	name: 'section',
	initialState: initSectionState,
	reducers: {
		fetchSections(state: SectionState, action: PayloadAction<Section[]>) {
			const sections = action.payload;
			sections.forEach((section) => {
				state.sections[section.id] = section;
			});
			if (state.selectedSectionId === null) {
				state.selectedSectionId = sections[0].id;
			}
		},
		dropCreateNewSectionFlag(state: SectionState, action: PayloadAction<{ id: string }>) {
			delete state.sections[action.payload.id].createNewEntity;
		},
		deleteTopicFromSection(state: SectionState, action: PayloadAction<{ sectionId: string; topicId: string }>) {
			const { sectionId, topicId } = action.payload;
			state.sections[sectionId].topicOrder = state.sections[sectionId].topicOrder.filter((id) => id !== topicId);
		},
		addNewSection: (state, action: PayloadAction<Section>) => {
			const section = action.payload;
			state.sections[section.id] = section;
			state.selectedSectionId = section.id;
		},
		selectSection: (state, action: PayloadAction<string>) => {
			state.selectedSectionId = action.payload;
		},
		updateSectionName: (state, action: PayloadAction<{ sectionId: string; name: string; language: Language }>) => {
			const section = state.sections[action.payload.sectionId];
			section.name = section.name.map((title) => {
				if (title.language === action.payload.language) {
					return { ...title, name: action.payload.name };
				}
				return title;
			});
		},
		removeLanguagesFromSections(state: SectionState, action: PayloadAction<Language[]>) {
			let removeLangs = action.payload;
			//do work across all sections
			Object.values(state.sections).forEach((section) => {
				section.name = section.name.filter((title) => !removeLangs.includes(title.language));
			});
		},
		addLanguagesToSections(
			state: SectionState,
			action: PayloadAction<{ languages: Language[]; languageToCopy: Language }>
		) {
			const { languages, languageToCopy } = action.payload;
			Object.values(state.sections).forEach((section) => {
				// If the language we're adding already exists, remove it. Happens when data is corrupt.
				section.name = section.name.filter((item) => !languages.includes(item.language));
				//get the name translation that matches the languageToCopy
				let nameToCopy = section.name.find((item) => item.language === languageToCopy);
				// for every language that needs added, make a copy of the nameToCopy
				languages.forEach((lang) => {
					const newName = { ...nameToCopy, language: lang };
					section.name.push(newName);
				});
			});
		},
		updateTopicOrder(state: SectionState, action: PayloadAction<{ sectionId: string; topicOrder: string[] }>) {
			const { sectionId, topicOrder } = action.payload;
			state.sections[sectionId].topicOrder = topicOrder;
		},
		saveSection(state: SectionState, action: PayloadAction<{ sectionId: string }>) {
			// this reducer does absolutley nothing. The action is intercepted by the Liiingo Middleware in store.ts, where it will fire a network save
			// this was added for handling the onBlur event when changing the section name
		},
		deleteSection(state: SectionState, action: PayloadAction<{ sectionId: string }>) {
			delete state.sections[action.payload.sectionId];
		},
		updateSection(state: SectionState, action: PayloadAction<Section>) {
			let section = state.sections[action.payload._id];
			let updatedSection = { ...section, ...action.payload };
			state.sections[action.payload._id] = updatedSection;
		},
		updateSectionStatus: (state, action: PayloadAction<{ sectionId: string; sectionStatus: Status }>) => {
			const section = state.sections[action.payload.sectionId];
			section.status = action.payload.sectionStatus;
		},
	},
	extraReducers: (builder) => {
		// Add reducers for Thunk action types here, and handle loading state as needed
	},
});

// Export reducer
export default sectionSlice.reducer;

// Export actions
export const {
	fetchSections,
	addNewSection,
	selectSection,
	addLanguagesToSections,
	removeLanguagesFromSections,
	updateSectionName,
	updateTopicOrder,
	dropCreateNewSectionFlag,
	deleteTopicFromSection,
	saveSection,
	deleteSection,
	updateSection,
	updateSectionStatus,
} = sectionSlice.actions;

// Export selectors
export const _sections = (state) => state.section.sections;
export const _selectedSection = (state) => state.section.sections[state.section.selectedsectionId];
export const _selectedSectionId = (state) => state.section.selectedSectionId;
export const _topicOrder = (state) => state.section.sections[state.section.selectedSectionId].topicOrder;
export const _topicOrders = (state) => {
	let topicOrderMap = {};
	Object.entries(state.section.sections).forEach(([key, value]: [string, Section]) => {
		topicOrderMap[key] = value.topicOrder;
	});
	return topicOrderMap;
};

export const createNewSection = (location: Location, sectionName: string) => {
	const languageNames = location.supportedLanguages.map((language: Language) => {
		return newTitle({ name: sectionName, language });
	});

	const section = newSection({
		id: new ObjectID().toHexString(),
		name: languageNames,
		status: 1,
		topicOrder: [],
		locationId: location.id,
		organizationId: location.organizationId,
		createNewEntity: true,
	});

	return section;
};
