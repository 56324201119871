const ID_TOKEN = 'id-token';
const PHP_ACCESS_TOKEN = 'php-access-token';
const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const IS_CLOSED = 'closed';
const IS_CLOSED_THREE_DAY = 'closed-three-day';
const IS_CLOSED_BILLING = 'closed-billing';
const IS_DISMISSED_DELETE = 'dismissed-delete';
const IS_DISMISSED_ML_DELETE = 'dismissed-ml-delete';
const ROUTE = 'route';
const CLOSED_NOT_SCANNABLE = 'closed-not-scannable';
const CLOSED_QR_HOVER = 'closed-qr-hover';
const CLOSED_QR_DIALOG = 'closed-qr-dialog';
const CLOSED_ACCOUNT = 'closed-account';
const DO_NOT_SHOW_SPONSORED_INVITE_DIALOG = 'do-not-show-sponsored-invite-dialog';

const DISMISSED_PAGE_DELETE = 'dismissed_page_delete';
const DISMISSED_LANGUAGE_DELETE = 'dismissed_language_delete';
const DISMISSED_SECTION_DELETE = 'dismissed_section_delete';

const PAGE_HIDDEN_TIP = 'page_hidden_tip';
const PAGE_INCOGNITO_TIP = 'page_incognito_tip';
const PAGE_TEMPORARY_TIP = 'page_temporary_tip';
const NEW_LANGUAGE_CONTENT_TIP = 'new_language_content_tip';

const INITIAL_CONTENT_DESIGNER_WALKTHROUGH = 'initial_content_designer_walkthrough';
const INITIAL_PAGE_PANEL_WALKTHROUGH = 'initial_page_panel_walkthrough';
const MANAGING_ELEMENTS_WALKTHROUGH = 'managing_elements_walkthrough';

const ACCEPTABLE_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif, image/svg+xml'];
const ACCEPTABLE_VIDEO_MIME_TYPES = ['video/webm', 'video/mp4'];
const ACCEPTABLE_IMAGE_FILE_EXTENSIONS = '.jpeg,.jpg,.png,.gif,.svg';
const ACCEPTABLE_QR_LOGO_FILE_EXTENSIONS = '.jpeg,.jpg,.png,.svg';

const JWPLAYER_VIDEO_URL = (id) => `https://content.jwplatform.com/videos/${id}.mp4`; //`https://content.jwplatform.com/videos/${previewUrl}.mp4`
const JWPLAYER_VIDEO_THUMB = (id) => `https://cdn.jwplayer.com/thumbs/${id}-360.jpg`; //`https://content.jwplatform.com/thumbs/${previewUrl}.jpg`

const MAX_IMAGE_UPLOAD_SIZE = 20000000; // 20MB

const HEADER_LOGO = 'header_logo';
const TOPIC_BACKGROUND = 'topic_background';
const ORG_NAME = 'org_name';
const APP_NAME = 'app_name';
const IMAGE_BANNER = 'banner';
const IMAGE_CONTENT = 'image';
const IMAGE_BUTTON = 'image_btn';
const IMAGE_LOGO = 'logo';
const IMAGE_PREVIEW = 'preview';
const VIDEO_CONTENT = 'video';
const VIDEO_PREVIEW = 'video_preview';
const LOGO_RADIUS = 25;
const PAGE_NAME_ID = 'page_name';
const SECTION_NAME_ID = 'section_name';
const SETTINGS_MENU = 'settings';
const SOCIAL_SHARING = 'social_sharing';

const LIIINGO_HELP_URL = 'https://support.liiingo.com';

const TITLE_MAP = {
	essentials: 'Liiingo Essentials',
	'sign-in': 'Sign In',
	'sign-out': 'Sign Out',
	'sign-up': 'Sign Up',
	organization: 'App Editor',
	billing: 'Account',
	dashboard: 'My Liiingo',
	profile: '', // removing this from page title for now
	checkout: 'checkout',
	'payment-confirmation': 'Payment Confirmation',
	'payment-canceled': 'Payment Canceled',
	'forgot-password': 'Forgot Password',
};

const MAIL_VALIDATION_REGEX = /.*\S+@.+\..+/;
const PHONE_NUMBER_VALIDATION_REGEX = /^\(?(\d{3})\)?[ ]?(\d{3})[-]?(\d{4})$/;
const SMS_VALIDATION_REGEX = /^\d{5}$|^\d{6}$|^\d{10}$|^\d{11}$|^\d{12}$/;

const STARTER_PLAN = 'Starter';

export {
	PHP_ACCESS_TOKEN,
	ACCESS_TOKEN,
	ID_TOKEN,
	REFRESH_TOKEN,
	CLOSED_NOT_SCANNABLE,
	CLOSED_QR_HOVER,
	CLOSED_QR_DIALOG,
	DO_NOT_SHOW_SPONSORED_INVITE_DIALOG,
	NEW_LANGUAGE_CONTENT_TIP,
	IS_CLOSED,
	IS_CLOSED_THREE_DAY,
	IS_CLOSED_BILLING,
	IS_DISMISSED_DELETE,
	IS_DISMISSED_ML_DELETE,
	ROUTE,
	TITLE_MAP,
	DISMISSED_PAGE_DELETE,
	DISMISSED_LANGUAGE_DELETE,
	DISMISSED_SECTION_DELETE,
	PAGE_HIDDEN_TIP,
	PAGE_INCOGNITO_TIP,
	PAGE_TEMPORARY_TIP,
	ACCEPTABLE_IMAGE_FILE_EXTENSIONS,
	ACCEPTABLE_QR_LOGO_FILE_EXTENSIONS,
	LIIINGO_HELP_URL,
	INITIAL_CONTENT_DESIGNER_WALKTHROUGH,
	INITIAL_PAGE_PANEL_WALKTHROUGH,
	MANAGING_ELEMENTS_WALKTHROUGH,
	TOPIC_BACKGROUND,
	ORG_NAME,
	APP_NAME,
	HEADER_LOGO,
	IMAGE_BANNER,
	IMAGE_CONTENT,
	IMAGE_BUTTON,
	IMAGE_LOGO,
	IMAGE_PREVIEW,
	VIDEO_CONTENT,
	VIDEO_PREVIEW,
	LOGO_RADIUS,
	ACCEPTABLE_IMAGE_MIME_TYPES,
	ACCEPTABLE_VIDEO_MIME_TYPES,
	PAGE_NAME_ID,
	SECTION_NAME_ID,
	CLOSED_ACCOUNT,
	MAIL_VALIDATION_REGEX,
	JWPLAYER_VIDEO_URL,
	JWPLAYER_VIDEO_THUMB,
	PHONE_NUMBER_VALIDATION_REGEX,
	SMS_VALIDATION_REGEX,
	STARTER_PLAN,
	SETTINGS_MENU,
	SOCIAL_SHARING,
	MAX_IMAGE_UPLOAD_SIZE,
};
