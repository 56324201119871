import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { styled } from '@mui/material/styles';
import { FlatButton } from '../Buttons/FlatButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { _selectedTopicId, _topics, updateTopic } from '../../store/slices/topicSlice';
import { _activeLanguageCode } from '../../store/slices/editorSlice';
import { bindActionCreators } from 'redux';
import { _selectedLocation } from '../../store/slices/locationSlice';

interface SocialSharingData {
	organizationName: string;
	pageTitle: string;
	description: string;
}

const Form = styled('form')(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '24px',
	width: '100%',
	height: '100%',
}));

const StyledTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root': {
		backgroundColor: '#F8F9FA',
		borderRadius: '8px',
		'& fieldset': {
			borderColor: '#E9ECEF',
		},
		'&:hover fieldset': {
			borderColor: '#CED4DA',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#1976d2',
		},
	},
	'& .MuiInputLabel-root': {
		color: '#495057',
		fontWeight: 500,
		'&.Mui-focused': {
			color: '#1976d2',
		},
	},
	'& .MuiInputBase-input': {
		padding: '14px 16px',
	},
	'& .MuiInputBase-multiline': {
		padding: '0',
	},
}));

const customStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			// height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		content: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			gap: '24px',
			padding: '0px 24px',
			overflowY: 'auto',
		},
		formFields: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: '5px',
			gap: '24px',
		},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			padding: '16px 24px',
			// borderTop: '1px solid #E9ECEF',
			backgroundColor: '#ffffff',
		},
		button: {
			height: 40,
			borderRadius: '8px',
			textTransform: 'none',
			padding: '8px 24px',
			fontWeight: 500,
		},
		helperText: {
			marginTop: '4px',
			color: '#6C757D',
			fontSize: '12px',
		},
	})
);

const RichSocialSharing: React.FC = () => {
	const topics = useAppSelector(_topics);
	const topicId = useAppSelector(_selectedTopicId);
	const activeLanguageCode = useAppSelector(_activeLanguageCode);
	const classes = customStyles();
	const dispatch = useAppDispatch();
	const updateMetadata = bindActionCreators(updateTopic, dispatch);
	const [enableButton, setEnableButton] = useState(false);
	const selectedLocation = useAppSelector(_selectedLocation);

	const [formData, setFormData] = React.useState<SocialSharingData>({
		...topics[topicId]?.metadata,
		organizationName: topics[topicId]?.metadata?.organizationName || selectedLocation?.name,
		pageTitle:
			topics[topicId]?.metadata?.pageTitle ||
			topics[topicId]?.name.find((n) => n.language === activeLanguageCode)?.name,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));

		// Enable button only if any field has changed from initial values
		const hasChanges = value !== topics[topicId]?.metadata?.[name];
		setEnableButton(hasChanges);
	};

	// Saving metadata to the store then save the topic to mongo
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		let clonedTopic = structuredClone(topics[topicId]);
		clonedTopic.metadata = formData;
		// Saving metadata
		updateMetadata(clonedTopic);
	};

	useEffect(() => {
		if (activeLanguageCode && topicId) {
			setEnableButton(false);
			const selectedTopic = topics[topicId];
			const pageName =
				selectedTopic?.metadata?.pageTitle ||
				selectedTopic?.name.find((n) => n.language === activeLanguageCode)?.name;
			setFormData((prev) => ({
				...prev,
				organizationName: selectedTopic?.metadata?.organizationName || selectedLocation?.name,
				pageTitle: pageName,
				description: selectedTopic?.metadata?.description || '',
			}));
		}
	}, [activeLanguageCode, selectedLocation?.name, topicId, topics]);

	return (
		<Box className={classes.wrapper}>
			<Form onSubmit={handleSubmit}>
				<Box className={classes.content}>
					<Box className={classes.formFields}>
						<StyledTextField
							fullWidth
							label="Organization Name"
							name="organizationName"
							value={formData.organizationName}
							onChange={handleChange}
							placeholder="Enter your organization name"
						/>

						<StyledTextField
							fullWidth
							label="Page Title"
							name="pageTitle"
							value={formData.pageTitle}
							onChange={handleChange}
							placeholder="Enter your page title"
						/>

						<StyledTextField
							fullWidth
							label="Page Description"
							name="description"
							value={formData.description}
							onChange={handleChange}
							multiline
							rows={4}
							placeholder="This description will appear when a link is shared on social media platforms like Facebook, LinkedIn, and X."
						/>
					</Box>
				</Box>

				<Box className={classes.buttonBox}>
					<FlatButton
						disabled={!enableButton}
						type="submit"
						className={classes.button}
						variant="contained"
						color="primary"
					>
						Save
					</FlatButton>
				</Box>
			</Form>
		</Box>
	);
};

export default RichSocialSharing;
